import React from 'react';
import { HallmarkChannelLogo, Logo } from '../../../images/logos';
// import { Step1, Step2, Step3 } from '../../../images/icons';
import './LandingHeader.scss';
import { useSelector } from 'react-redux';
import { selectIsCurrentSweepstake } from '../../../slices/sweepstakes/selectors';
import HallmarkCrown from '../../Shared/HallmarkCrown';
import RootState from 'types';
import SponsorLogo from './SponsorLogo';

interface LandingHeaderProps {
  page: 'landing' | 'signup';
  children: JSX.Element;
}

const LandingHeader = ({ page, children }: LandingHeaderProps) => {
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);
  const gradientColors = ['#802595', '#802595', '#802595'];
  const { id: userId, acceptedRules } = useSelector((state: RootState) => state.user);

  return (
    <div className={`landing_header ${isCurrentSweepstake ? 'sweepstakes' : 'game'}_landing-header`}>
      <div className="logo-container">
        <HallmarkChannelLogo color={isCurrentSweepstake ? '#1F4047' : '#652B82'} />
        <div className="logo-wrapper">
          <Logo type="stacked" colors={isCurrentSweepstake ? ['#F08E1D', '#652B82'] : ['#F08E1D', '#652B82']} />
          {/* FOR SPONSORSHIP  */}
          {/* <SponsorLogo type="horizontal" /> */}
        </div>
      </div>
      {isCurrentSweepstake ? (
        <div className="enter-now-box enter-now-box-sweepstakes">
          {isCurrentSweepstake ? <SponsorLogo type="stacked" /> : null}

          <HallmarkCrown />
          {/* <h3 className="landing-header-subtitle">
            Enter and play for a chance to&nbsp;win&nbsp;each&nbsp;week&nbsp;a:
          </h3> */}
          <h2 className="landing-header-title">
            Enter for a chance to win a Cruise for Two from Princess.<span className="text-highlight-link">*</span>
          </h2>
          <p className="landing-header-return-daily">Return Daily for Bonus Entries</p>
          <div className="landing-header-awarded">
            <span className="text-highlight-link">*</span>awarded in the form of a $3,100 USD digital Princess Cruises
            promotional card
          </div>

          {children}
        </div>
      ) : (
        <>
          <div className="enter-now-box">
            <HallmarkCrown />
            <h2 className="enter-now-box-copy">
              Enter and play to see if you can complete all of Hallmark Channel’s Matching Moments!
            </h2>
            {children}
          </div>
        </>
      )}

      {/* {page === 'landing' && isCurrentSweepstake && (
        <div
          className={classNames(
            'landing-header-steps',
            !userId || acceptedRules ? '' : 'landing-header-steps-checkboxes'
          )}
        >
          <div className="landing-header-step">
            <Step1 colors={gradientColors} />
            <h5>
              <span className="bold">10</span>
              <span>Weeks</span>
            </h5>
          </div>
          <div className="landing-header-step">
            <div className="landing-header-step-icon">
              <Step2 colors={gradientColors} />
            </div>
            <h5>
              <span className="bold">10</span>
              <span>Sweepstakes</span>
            </h5>
          </div>
          <div className="landing-header-step">
            <Step3 colors={gradientColors} />
            <h5>
              <span className="bold">10</span>
              <span>Prize Draws</span>
            </h5>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default LandingHeader;
