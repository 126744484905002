import './Layout/LandingHeader/LandingHeader.scss';

import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormFeedback } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { localStorageKey } from '../constants';
import FormValidationStandardSchema from '../helpers/FormValidationStandardSchema';
import FormValidationSweepstakesSchema from '../helpers/FormValidationSweepstakesSchema';
import callApi from '../infrastructure/api/CallApi';
import { trackEvent } from '../infrastructure/tracking/GoogleAnalytics';
import { login } from '../slices/users/actions';
import { UserDto } from '../types';
import Button from './Layout/Button';
import TextField from './Form/TextField';
import useLinkTarget from '../infrastructure/isWebView/useLinkTarget';
import Swal from 'sweetalert2';
import RootState from '../types/index';
import { formPage } from '../types/pageNameContants';
import usePageView from '../infrastructure/hooks/usePageView';
import { selectIsCurrentSweepstake } from '../slices/sweepstakes/selectors';
import { setFormValuesOnCookie, setInitialFromStateFromValuesOnCookie } from '../sdk/FormCookie';
import { GameRoute, GroupInvitePageRoute, LandingRoute, UrlNameKeyWithColon } from 'constants/Routes';
import { HallmarkChannelLogo, Logo } from '../images/logos';
import HallmarkCrown from './Shared/HallmarkCrown';
import PROVIDERS from '../constants/providers';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import SponsorLogo from './Layout/LandingHeader/SponsorLogo';
import { Link } from 'react-router-dom';

const zipCodeMask = () => {
  const maskArray = [];
  for (let i = 0; i < 5; i++) {
    maskArray.push(/[0-9]/);
  }
  return maskArray;
};

const FormPage: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const linkTarget = useLinkTarget();
  const inviteCode = useSelector((state: RootState) => state.share.shareCode);
  const groupInviteCode = useSelector((state: RootState) => state.groupInvite.code);
  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  usePageView(formPage, '/signup');

  const onSubmit = (values: object) => {
    trackEvent('Entry Form', 'Entry Form Submitted');
    setIsSubmitting(true);

    const apiUrl = 'api/app/user/' + (isCurrentSweepstake ? 'enter-form' : 'enter-standard-form');
    callApi<UserDto>(apiUrl, 'POST', values)
      .then(({ data }) => {
        setFormValuesOnCookie(values);
        onSignUpSuccess(data);
      })
      .catch(({ response }: any) => {
        handleError(response);
        setIsSubmitting(false);
      });
  };

  const onSignUpSuccess = (user: UserDto) => {
    const eventType = user.isNew ? 'Sign Up' : 'Sign In';
    trackEvent(eventType, `${eventType} Successful`);
    dispatch(login(user));
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(user));
    } catch {}
    const redirectPath = groupInviteCode
      ? GroupInvitePageRoute.replace(UrlNameKeyWithColon, groupInviteCode)
      : GameRoute;
    history.push(redirectPath);
  };

  const handleError = (response: any) => {
    let alertDescription = 'Something went wrong! Please try again.';
    if (response && response.data && response.data.error && response.data.error.code === 'CustomError') {
      alertDescription = response.data.error.message;
    }
    Swal.fire({
      title: 'Oops!',
      text: alertDescription,
      backdrop: false,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const initialValues = setInitialFromStateFromValuesOnCookie({
    firstName: '',
    lastName: '',
    emailAddress: '',
    crownMediaOptIn: false,
    ageConfirmation: false,
    rules: false,
    sponsorOptIn: false,
    princessCruisesSponsorOptIn: false,
    inviteCode,
    provider: '',
    zipCode: '',
  });

  return (
    <>
      <div className="page page-landing">
        <div className="landing_header">
          <div className="logo-container">
            <HallmarkChannelLogo color={isCurrentSweepstake ? '#1F4047' : '#652B82'} />
            <div className="logo-wrapper">
              <Link to={LandingRoute}>
                <Logo type="stacked" colors={isCurrentSweepstake ? ['#F08E1D', '#652B82'] : ['#F08E1D', '#652B82']} />
              </Link>
              {/* FOR SPONSORSHIP  */}
              {/* <SponsorLogo className="" /> */}
            </div>
          </div>
        </div>
        {/* <LandingHeader page="signup">
          <>
            <h1>Sign up to start playing</h1>
          </>
        </LandingHeader> */}
        <div className="form-padding">
          <Container className="form-container">
            {isCurrentSweepstake ? <SponsorLogo type="stacked" /> : null}
            <div className="form-crown-container">
              <HallmarkCrown />
            </div>
            <h2>Sign Up to Start Playing</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={isCurrentSweepstake ? FormValidationSweepstakesSchema : FormValidationStandardSchema}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched }) => (
                <Form id="EntryForm" noValidate>
                  <Row>
                    <Col xs={12} sm={{ span: 6, offset: 0 }}>
                      <div className="text-field">
                        <TextField name="firstName" label="First Name" id="firstName" type="text" maxLength={20} />
                      </div>
                    </Col>
                    <Col xs={12} sm={{ span: 6, offset: 0 }}>
                      <div className="text-field">
                        <TextField name="lastName" label="Last Name" id="lastName" type="text" maxLength={20} />
                      </div>
                    </Col>
                    <Col xs={12} sm={{ span: isCurrentSweepstake ? 6 : 12, offset: 0 }}>
                      <div className="text-field">
                        <TextField
                          name="emailAddress"
                          label="Email Address"
                          id="emailaddress"
                          type="email"
                          maxLength={100}
                        />
                      </div>
                    </Col>
                    {isCurrentSweepstake ? (
                      <Col xs={12} sm={{ span: 6, offset: 0 }}>
                        <div
                          className={classNames(
                            'text-field',
                            errors.zipCode && touched.zipCode ? 'input-show-error' : ''
                          )}
                        >
                          <label className="input-label">Zip Code</label>
                          <Field
                            name="zipCode"
                            render={({ field }: any) => (
                              <div className="zip-code-container">
                                <MaskedInput
                                  {...field}
                                  mask={zipCodeMask}
                                  id="zipCode"
                                  type="text"
                                  placeholder=""
                                  maxLength={100}
                                  aria-label="Zip Code"
                                  guide={false}
                                  pattern="[0-9]*"
                                  inputMode="numeric"
                                  className={classNames(
                                    'form-control',
                                    errors.zipCode && touched.zipCode ? 'input-show-error' : ''
                                  )}
                                />
                              </div>
                            )}
                          />
                          {errors.zipCode && touched.zipCode ? (
                            <p className="invalid-tooltip">{errors.zipCode}</p>
                          ) : null}
                          {/* <TextField name="zipCode" label="Zip Code" id="zipCode" type="zip" maxLength={5} /> */}
                        </div>
                      </Col>
                    ) : null}

                    {isCurrentSweepstake ? (
                      <Col xs={12} sm={{ span: 12, offset: 0 }}>
                        <div
                          className={classNames(
                            'text-field',
                            errors.provider && touched.provider ? 'input-show-error' : ''
                          )}
                        >
                          <label className="input-label">Cable Provider</label>
                          <Field
                            name="provider"
                            id="provider"
                            type="text"
                            aria-label="Provider"
                            render={({ field }: any) => (
                              <div className="select-wrapper">
                                <select className={classNames(values.provider === '' ? 'placeholder' : '')} {...field}>
                                  <option disabled value="">
                                    Select&hellip;
                                  </option>
                                  {PROVIDERS.map((provider) => (
                                    <option value={provider.value} key={provider.value}>
                                      {provider.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                          {errors.provider && touched.provider ? (
                            <p className={classNames('invalid-tooltip')}>{errors.provider}</p>
                          ) : null}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                  <Row>
                    <Col xs={12} sm={{ span: 12, offset: 0 }}>
                      {isCurrentSweepstake && (
                        <label htmlFor="ageConfirmation" className="checkbox-container">
                          <Field
                            name="ageConfirmation"
                            type="checkbox"
                            id="ageConfirmation"
                            checked={values.ageConfirmation}
                          />
                          <span className="checkmark" />
                          <p>
                            I am at least 21 years old or the age of majority in my place of residence.
                            <span className="red">*</span>
                          </p>
                          {touched.ageConfirmation && (
                            <FormFeedback tooltip valid={!errors.ageConfirmation}>
                              {errors.ageConfirmation}
                            </FormFeedback>
                          )}
                        </label>
                      )}

                      {isCurrentSweepstake && (
                        <>
                          <label htmlFor="rules" className="checkbox-container">
                            <Field name="rules" type="checkbox" id="rules" checked={values.rules} />
                            <span className="checkmark" />

                            <p>
                              I agree to the Sweepstakes{' '}
                              <a href="/rules" target={linkTarget} rel="noopener noreferrer">
                                Official Rules
                              </a>
                              , as well as Hallmark Media's{' '}
                              <a
                                href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use/?utm_source=matching_momements&utm_medium=entry_form&utm_campaign=loveuary_sweepstakes"
                                target={linkTarget}
                                rel="noopener noreferrer"
                              >
                                Terms&nbsp;of&nbsp;Use
                              </a>{' '}
                              and{' '}
                              <a
                                href="https://www.hallmarkchannel.com/privacy-policy/?utm_source=matching_momements&utm_medium=entry_form&utm_campaign=loveuary_sweepstakes"
                                target={linkTarget}
                                rel="noopener noreferrer"
                              >
                                Privacy&nbsp;Policy
                              </a>
                              .<span className="red">*</span>
                            </p>
                            {touched.rules && (
                              <FormFeedback tooltip valid={!errors.rules}>
                                {errors.rules}
                              </FormFeedback>
                            )}
                          </label>
                        </>
                      )}

                      <label htmlFor="crownMediaOptIn" className="checkbox-container">
                        <Field
                          name="crownMediaOptIn"
                          type="checkbox"
                          id="crownMediaOptIn"
                          checked={values.crownMediaOptIn}
                        />
                        <span className="checkmark" />

                        <p>
                          By checking this box, I agree that Hallmark Media and its affiliated companies can email me
                          about products/services that may be of interest to me, including special offers and
                          promotions.
                        </p>
                      </label>
                      {isCurrentSweepstake ? (
                        <label htmlFor="princessCruisesSponsorOptIn" className="checkbox-container">
                          <Field
                            name="princessCruisesSponsorOptIn"
                            type="checkbox"
                            id="princessCruisesSponsorOptIn"
                            checked={values.princessCruisesSponsorOptIn}
                          />
                          <span className="checkmark" />
                          <p>
                            I would like to receive marketing and promotional email messages from{' '}
                            <a
                              href="https://www.princess.com?utm_source=matching_momements&utm_campaign=loveuary_sweepstakes"
                              rel="noreferrer noopener"
                              target="_blank"
                              onClick={() => trackEvent('Princess Cruises Opt In', 'Princess Cruises Click', 'Form')}
                            >
                              Princess Cruise Lines, Ltd
                            </a>
                            . (not required for Sweepstakes entry).
                          </p>
                        </label>
                      ) : null}
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    withArrow
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Start Playing
                  </Button>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FormPage;
